import api from "@/services/Api";

export const createAccount = async ({
  email,
  name,
  phone,
  password,
  company,
  marketing_email_enabled,
  marketing_sms_enabled,
}) => {
  const resp = await api.post("/v2/store/customers/signup", {
    email,
    name,
    phone,
    password,
    store: 2,
    marketing_email_enabled,
    marketing_sms_enabled,
    ...(company && { company }),
  });

  return resp.data;
};

export const signIn = async ({ email, password }) => {
  const resp = await api.post("/v2/store/customers/token", {
    email,
    password,
    store_id: 2,
  });

  return resp.data;
};

export const sendResetPasswordEmail = async ({ email }) => {
  const resp = await api.post("/v2/store/customers/send-reset-email", {
    email,
  });

  return resp.data;
};

export const resetPassword = async ({ uid, reset_token, password }) => {
  const resp = await api.put(`/v2/store/customers/reset-password/${uid}`, {
    reset_token,
    password,
  });

  return resp.data;
};
