import { Popover, PopoverBackdrop, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";

export const PopoverNavigation = ({ title = "", items = [], className = "", children }) => {
  return (
    <Popover className={classNames("relative group", className)}>
      {({ open, close }) => {
        return (
          <>
            <PopoverButton className="flex focus:outline-none text-gray-800 hover:text-gray-500 mt-0">
              <span className={`text-[20px] font-semibold ${open ? "text-blue-500" : ""}`}>
                {title}
              </span>{" "}
              <ChevronDownIcon
                className={`h-[22px] w-[22px] ml-[3px] mt-[4.45px] scale-y-[1.07] ${
                  open ? "rotate-180 text-blue-500" : ""
                }`}
              />
            </PopoverButton>
            <PopoverBackdrop className="fixed inset-0" />
            <PopoverPanel
              transition
              className="bg-white drop-shadow-[0_2px_5px_#0003] rounded-[20px] pt-2.5 pb-5 flex flex-col min-w-[320px] mt-6 z-[2000] transition duration-200 ease-in-out data-[closed]:-translate-y-1 data-[closed]:opacity-0"
              anchor="bottom"
            >
              {items.map(({ Icon, ...item }) => (
                <Link
                  key={item.name}
                  href={item.href}
                  {...(!item.prefetch && { prefetch: false })}
                  onClick={close}
                  className="cursor-pointer text-lg text-black hover:text-blue-500 rounded-[12px] transition-all duration-200 px-5 py-2.5 flex items-center justify-left space-x-4 focus:outline-none"
                >
                  {item?.image && <Image src={item.image} width={70} height={70} alt={item.name} />}
                  {Icon && (
                    <div className="bg-[#E9EFFD] rounded-lg flex items-center justify-center w-[62px] h-[62px]">
                      <Icon width={32} height={32} />
                    </div>
                  )}
                  <span>{item.name}</span>
                </Link>
              ))}
              {children}
            </PopoverPanel>
          </>
        );
      }}
    </Popover>
  );
};
