import { createAccount, resetPassword, signIn } from "@/services/AuthService";
import CredentialsProvider from "next-auth/providers/credentials";

export const nextAuthOptions = {
  providers: [
    CredentialsProvider({
      id: "credentials",
      name: "Make My Fresheners",
      type: "credentials",
      credentials: {
        email: { label: "email", type: "email" },
        password: { label: "Password", type: "password" },
        name: { label: "Name", type: "text" },
        company: { label: "Company", type: "text" },
      },
      async authorize(credentials) {
        const {
          email: caseSensitiveEmail,
          password,
          name,
          phone,
          company,
          isSignUp,
          isResetPassword,
          uid,
          reset_token,
          marketing_email_enabled,
          marketing_sms_enabled,
        } = credentials;

        let resp;

        const lowerCaseEmail = caseSensitiveEmail?.toLowerCase();

        if (isSignUp) {
          resp = await createAccount({
            email: lowerCaseEmail,
            password,
            name,
            phone,
            company,
            marketing_email_enabled,
            marketing_sms_enabled,
          }).catch((err) => {
            if (err.message === "The fields store, email must make a unique set.")
              throw new Error("Email already registered");
            throw err;
          });
        } else if (isResetPassword) {
          resp = await resetPassword({ uid, reset_token, password });
        } else {
          resp = await signIn({ email: lowerCaseEmail, password });
        }

        return resp;
      },
    }),
  ],
  secret: process.env.JWT_SECRET,
  pages: {
    signIn: "/auth/signin",
  },
  callbacks: {
    async jwt({ token, user, account }) {
      if (account && user) {
        return {
          token: user.token,
          id: user.id,
          email: user.email,
          name: user.name,
        };
      }

      return token;
    },

    async session({ session, token }) {
      session.user.accessToken = token.token;
      session.user.id = token.id;
      session.user.email = token.email;
      return session;
    },
  },
  theme: {},
  // Enable debug messages in the console if you are having problems
  debug: process.env.NODE_ENV === "development",
  trustHost: true,
};
