const variantButtons = {
  danger: {
    confirm: "px-5 red-button",
    cancel: "px-5 clear-button",
  },
  default: {
    confirm: "px-5 blue-button",
    cancel: "px-5 clear-button",
  },
};

const HeadlessModal = ({
  isOpen = false,
  onClose,
  title = "",
  text = "",
  variant = "default",
  onConfirm = () => undefined,
  onCancel = () => undefined,
  customTitle = "",
  className = "max-w-sm",
  confirmText = "Yes",
  cancelText = "No",
  customActions = "",
  content = null,
}) => {
  if (!isOpen) return null;

  const buttonClasses = variantButtons[variant] || variantButtons.default;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="headless-modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[3002] px-4"
      onClick={handleOverlayClick}
    >
      <div className={`relative bg-white rounded-lg shadow-lg pb-0 w-full ${className}`}>
        <button
          className="text-gray-600 hover:text-gray-900 text-3xl absolute right-4 top-2 cursor-pointer z-10"
          onClick={onClose}
        >
          &times;
        </button>
        {content && content}
        {!content && (
          <div className="text-gray-700 md:text-center space-y-4 text center">
            {customTitle && customTitle}
            {!customTitle && title && <h3 className="text-xl mt-5 font-bold ">{title}</h3>}
            <div>{text}</div>
            <div className="flex mt-6 w-full justify-end space-x-4 border-t border-gray-200 pt-3">
              {customActions && customActions}
              {!customActions && (
                <>
                  <button className={buttonClasses.cancel} type="button" onClick={onCancel}>
                    {cancelText}
                  </button>
                  <button className={buttonClasses.confirm} type="button" onClick={onConfirm}>
                    {confirmText}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeadlessModal;
